











































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { ApolloTask, FileHarvesterConfiguration, FilesData, TFile } from '../../../types';
import { FileType } from '../../../constants';
import { JsonParser, SvgImage, FormBlock } from '@/app/components';
import * as R from 'ramda';
import { useApolloTask } from '../../../composable';
import { ResponseHandling, FilesUpload, SampleTable } from '../common';

export default defineComponent({
    name: 'ReviewFileHarvester',
    components: {
        JsonParser,
        ResponseHandling,
        SvgImage,
        FormBlock,
        FilesUpload,
        SampleTable,
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<FileHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        files: {
            type: Object as PropType<FilesData>,
            required: true,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
        sampleFields: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
        structure: {
            type: Object as PropType<Record<string, string>>,
            default: () => {},
        },
        fileProgress: {
            type: Object as PropType<{ id: number; current: number }>,
            default: () => ({ id: -1, current: 0 }),
        },
    },
    setup(props, { emit }) {
        const defaultBasePath = 'res';
        const separator = '||';
        const { inDraftStatus } = useApolloTask(computed(() => props.task));
        const finalSample = ref<any>(R.clone(props.sample));

        const isJSONorXML = computed(() =>
            [FileType.JSON, FileType.XML].includes(props.task.configuration.fileType as FileType),
        );

        const focusPath = computed(() =>
            props.task.configuration.response?.basePath !== defaultBasePath
                ? props.task.configuration.response?.basePath
                : null,
        );

        const uploadedFiles = computed(() => Object.values(props.files).filter((f: TFile) => !R.isNil(f)));

        const modifyFinalSample = (sample: any) => {
            finalSample.value = sample;
            emit('final-sample-updated', sample);
        };

        if (props.task.configuration.response) props.task.configuration.response.basePath = defaultBasePath;

        return {
            R,
            separator,
            inDraftStatus,
            FileType,
            defaultBasePath,
            isJSONorXML,
            modifyFinalSample,
            finalSample,
            uploadedFiles,
            focusPath,
        };
    },
});
