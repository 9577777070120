




























































import { DropdownMenu } from '@/app/components';
import { useExecutionErrors } from '@/app/composable';
import {
    CheckCircleIcon,
    DotsVerticalIcon,
    ExclamationCircleIcon,
    MenuIcon,
    XCircleIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch, PropType } from '@vue/composition-api';
import { useCleaning } from '../../../composable';
import ConstraintText from './ConstraintText.vue';
import { CleaningFieldConfiguration, Constraint } from '../../../types/cleaning.type';

export default defineComponent({
    name: 'ViewConstraint',
    components: {
        DropdownMenu,
        CheckCircleIcon,
        MenuIcon,
        XCircleIcon,
        DotsVerticalIcon,
        ConstraintText,
        ExclamationCircleIcon,
    },
    props: {
        types: {
            type: Array,
            required: true,
        },
        constraint: {
            type: Object as PropType<Constraint>,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: true,
        },
        errorCode: {
            type: Number,
            required: false,
        },
        isDropped: {
            type: Boolean,
            default: false,
        },
        hasStats: {
            type: Boolean,
            default: false,
        },
        isAdvancedConstraint: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Array as PropType<CleaningFieldConfiguration[]>,
            default: () => [],
        },
        ending: {
            type: String,
            default: ',',
        },
    },
    setup(props: any, { emit }: any) {
        const inHoverMode = ref(false);
        const getOutliersRuleText = ref<any>();
        const { errorMessage } = useExecutionErrors();

        const options = ref([
            {
                name: 'Edit',
                action: () => {
                    emit('edit', props.constraint);
                },
            },
            {
                name: 'Delete',
                requiresConfirm: true,
                action: () => {
                    emit('delete', props.constraint);
                },
            },
        ]);

        const typesRef = computed(() => props.types);

        watch(
            () => props.types,
            () => {
                const cleaning = useCleaning(typesRef as any, props.fields);
                getOutliersRuleText.value = cleaning.getOutliersRuleText;
            },
            { immediate: true },
        );

        return { inHoverMode, getOutliersRuleText, options, errorMessage };
    },
});
