






























































































































import { Scrollbar, Toggle } from '@/app/components';
import { useAxios, useJsonObject } from '@/app/composable';
import store from '@/app/store';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { ApolloAPI } from '../api';
import { ApolloTaskShell } from '../components';
import FieldView from '../components/encryption/FieldView.vue';
import { useApolloPipeline, useApolloTask, useSampleFields } from '../composable';
import { TaskStatus } from '../constants';
import { ApolloTask, WizardAction } from '../types';
import { EncryptionConfigurationType } from '../types/encryption.type';

export default defineComponent({
    name: 'Encryption',
    metaInfo() {
        return { title: `Encrypting${(this as any).task ? ` for: ${(this as any).task.pipeline.name}` : ''}` };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    components: {
        ApolloTaskShell,
        FieldView,
        Scrollbar,
        Toggle,
        ChevronRightIcon,
    },
    setup(props, { root }) {
        const { loading, error: encryptionError, exec } = useAxios(true);

        const hasChanges = ref<boolean>(false);
        const showFinalizeModal = ref<boolean>(false);
        const restartedStep = ref<boolean>(false);
        const task = ref<ApolloTask<EncryptionConfigurationType> | undefined>(undefined);
        const selectedFieldIdx = ref<number | null>(null);

        const alternateNames = ref<Record<string, { path: string[]; title: string[] }> | null>(null);
        const showAlternateNaming = ref<boolean>(false);
        const hasAlternateNames = computed(() => alternateNames.value && Object.keys(alternateNames.value).length);

        const { extractMappingFieldNames, extractAlternateNames } = useSampleFields();

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'save',
                show: !isFinalized.value,
                enabled: hasChanges.value,
                showCancel: !isFinalized.value && hasChanges.value,
            },
            { key: 'finalize', show: !isFinalized.value, enabled: !isFinalized.value },
            { key: 'revise', label: 'Restart', show: canRevise.value, enabled: canRevise.value },
        ]);
        const pipelineId = computed(() => props.id);

        const { mapping, error: pipelineError, fetchPipeline } = useApolloPipeline(pipelineId, undefined, false);
        const { canRevise, shouldUpdateAssetsAfterRevise, updateAssetsAfterRevise, isFinalized } = useApolloTask(task);

        const error = computed(() => encryptionError.value || pipelineError.value);

        const configuration = ref<EncryptionConfigurationType>({
            fields: [],
            schema: {},
        });

        const fetch = async () => {
            await fetchPipeline();
            task.value = (await exec(ApolloAPI.getTask(props.id, 'encryption')))?.data;
            configuration.value = task.value?.configuration ?? configuration.value;

            if (mapping.value) {
                alternateNames.value = extractAlternateNames(mapping.value.configuration);
                // initialise encryption fields
                configuration.value.fields = extractMappingFieldNames(mapping.value.configuration.fields).map(
                    (field, i) => ({
                        ...field,
                        index: configuration.value.fields[i]?.index ?? false, // by default no field should be indexed
                    }),
                );
            }
        };

        const { getFixedJSON } = useJsonObject();
        const { extractFieldSample } = useSampleFields();

        const sample = computed(() => {
            if (!task.value?.inputSample) return [];
            return getFixedJSON(task.value.inputSample);
        });

        const selectedField = computed(() =>
            selectedFieldIdx.value !== null ? configuration.value.fields[selectedFieldIdx.value] : null,
        );

        const selectedFieldSample = computed(() => {
            if (!selectedField.value) return null;
            return {
                ...selectedField.value,
                sample: extractFieldSample(sample.value, selectedField.value.title as string, selectedField.value.path),
            };
        });

        const indexAll = (index: boolean) => {
            hasChanges.value = true;
            configuration.value.fields.forEach((field) => {
                // if field can be indexed in ES, then toggle it, otherwise it is always false
                field.index = field.indexES ? index : false;
            });
        };

        const save = async () => {
            if (!task.value) return;
            try {
                await exec(ApolloAPI.updateTask(props.id, 'encryption', configuration.value));
                (root as any).$toastr.s('Encryption configuration saved successfully', 'Success');
                hasChanges.value = false;
            } catch (e) {
                (root as any).$toastr.e('Saving encryption configuration failed', 'Failed');
                hasChanges.value = true;
            }
        };

        const finalize = async () => {
            if (!task.value) return;
            if (hasChanges.value) await save();

            if (shouldUpdateAssetsAfterRevise()) {
                await updateAssetsAfterRevise();
                restartedStep.value = true;
            }
            await exec(ApolloAPI.finalizeTask(props.id, 'encryption'));
            showFinalizeModal.value = true;
        };

        const cancel = () => {
            root.$router.push({ name: 'data-checkin-jobs', query: store.state.queryParams.jobs });
        };

        const restart = async () => {
            if (!task.value) return;
            await exec(ApolloAPI.run(props.id));
            root.$router.push({ name: 'data-checkin-jobs', query: store.state.queryParams.jobs });
        };

        const toggleSelection = (idx: number) => {
            if (selectedFieldIdx.value === idx) selectedFieldIdx.value = null;
            else selectedFieldIdx.value = idx;
        };

        const unlockJob = async () => {
            await exec(ApolloAPI.unlock(props.id));
        };

        onMounted(async () => {
            window.addEventListener('beforeunload', unlockJob);
        });

        onUnmounted(async () => {
            unlockJob();
        });

        fetch();

        return {
            cancel,
            restart,
            finalize,
            toggleSelection,
            save,
            indexAll,

            configuration,
            hasChanges,
            isFinalized,
            selectedFieldIdx,
            wizardActions,
            TaskStatus,
            selectedFieldSample,
            loading,
            task,
            showFinalizeModal,
            hasAlternateNames,
            showAlternateNaming,
            alternateNames,
            error,
            restartedStep,
        };
    },
});
