














import { defineComponent, PropType } from '@vue/composition-api';
import { ExclamationCircleIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'ConstraintErrors',
    components: {
        ExclamationCircleIcon,
    },
    props: {
        errors: {
            type: Array as PropType<string[]>,
            required: true,
        },
    },
});
