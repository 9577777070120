


















































import { ConfirmModal } from '@/app/components';
import { useAxios, useSockets } from '@/app/composable';
import store from '@/app/store';
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { ApolloAPI } from '../../../api';
import { ApolloTaskShell } from '../../../components';
import { useApolloTask, usePolicyFileUpload, useSampleRun } from '../../../composable';
import { FileType, TaskStatus } from '../../../constants';
import { ApolloTask, FileHarvesterConfiguration, FilesData, TaskStep, TFile, WizardAction } from '../../../types';

export default defineComponent({
    name: 'FileHarvester',
    props: {
        task: {
            type: Object as PropType<ApolloTask<FileHarvesterConfiguration>>,
            required: true,
        },
        steps: {
            type: Array as PropType<TaskStep[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConfirmModal,
        ApolloTaskShell,
    },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();
        const { uploadPolicyFiles, fileProgress } = usePolicyFileUpload(props.task.pipeline.id);

        const currentStep = ref<number>(0);
        const loadingFileHarvester = ref(false);

        const taskRef: Ref<ApolloTask<FileHarvesterConfiguration>> = ref<ApolloTask<FileHarvesterConfiguration>>(
            props.task,
        );

        const {
            loading: loadingTask,
            isFinalized,
            taskStructure,
            save,
            finalize,
            fetchStructure,
            clearTaskStructure,
        } = useApolloTask(taskRef);

        const showAppendFileModal = ref<boolean>(false);
        const hasAdditionalFile = ref<boolean>(false);
        const sample = ref<any>(props.task.processedSample);
        const finalSample = ref<any>(props.task.processedSample);
        const files = ref<FilesData>({
            sampleFile: null,
            dataFile: null,
        });
        const showFinalizeModal = ref<boolean>(false);

        const finalLoading = computed(() => props.loading || loadingTask.value || loadingFileHarvester.value);

        const wizardActions = computed<Partial<WizardAction>[]>(() => [
            {
                key: 'sample-run',
                show: hasSampleRun.value && !taskRef.value?.processedSample?.length,
                enabled: !finalLoading.value && !!canExecuteSampleRun.value,
            },
            {
                key: 'view-processed-sample',
                show: !!hasSampleRun.value && !!taskRef.value?.processedSample?.length,
                enabled: true,
            },
            { key: 'append', show: !!taskRef.value?.canAddFiles, enabled: !!canAppendFiles.value },
            {
                key: 'finalize',
                show: !isFinalized.value,
                enabled: !finalLoading.value && taskRef.value?.status !== TaskStatus.Deprecated && canBeFinalized.value,
            },
        ]);

        const updateProcessedSample = async (processedSample: Array<Record<string, null>>) => {
            if (!processedSample) return;
            setSample(processedSample);
            taskRef.value.processedSample = processedSample;
            currentStep.value = currentStep.value + 1;
            await fetchStructure();
        };

        const runOnSample = async () => {
            await save();
            await uploadPolicyFiles([
                {
                    file: files.value.sampleFile as TFile,
                    sample: true,
                    policy: {
                        folder: 'upload',
                        subfolder: `sample/${new Date().valueOf().toString()}`,
                    },
                },
            ]);
            executeSampleRun();
        };

        const hasSampleRun = computed(
            () => currentStep.value === 0 && taskRef.value.configuration.fileType === FileType.Parquet,
        );
        const validInputPath = computed(() => {
            if (!taskRef.value.configuration.inputFilename) return false;

            const winPath = new RegExp(`([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?(.parquet)$`);
            const linuxPath = new RegExp(`^(/[^/]*)+/?(.parquet)$`);

            return (
                winPath.test(taskRef.value.configuration.inputFilename) ||
                linuxPath.test(taskRef.value.configuration.inputFilename)
            );
        });
        const canExecuteSampleRun = computed(
            () =>
                !!files.value.sampleFile &&
                ((taskRef.value.pipeline.isOnPremise && validInputPath.value) ||
                    (!taskRef.value.pipeline.isOnPremise && !!files.value.dataFile)),
        );
        const canAppendFiles = computed(() => !!files.value.dataFile);

        const { executeSampleRun, loadingSampleRun, onMessage } = useSampleRun(taskRef, root, updateProcessedSample);

        const isJSONorXML = computed(() =>
            [FileType.JSON, FileType.XML].includes(taskRef.value.configuration.fileType as FileType),
        );

        const canBeFinalized = computed(
            () =>
                currentStep.value === 1 &&
                ((!taskRef.value.pipeline.isOnPremise && !!files.value.dataFile) ||
                    (taskRef.value.pipeline.isOnPremise && !!taskRef.value.configuration.inputFilename)) &&
                (!!files.value.sampleFile || !!sample.value) &&
                (!isJSONorXML.value || !!taskRef.value.configuration.response.selectedItems.length),
        );

        const sampleFields = computed(() => (!sample.value?.length ? [] : Object.keys(sample.value[0])));

        const setFiles = (changedFiles: FilesData) => {
            if (changedFiles.sampleFile !== undefined) {
                files.value.sampleFile = changedFiles.sampleFile;
                if (changedFiles.sampleFile === null) {
                    taskRef.value.processedSample = [];
                    sample.value = null;
                    finalSample.value = null;
                }
            }
            if (changedFiles.dataFile !== undefined) {
                files.value.dataFile = changedFiles.dataFile;
                if (taskRef.value.canAddFiles) hasAdditionalFile.value = !!changedFiles.dataFile;
            }
        };

        const setSample = (sampleData: any) => {
            sample.value = sampleData;
            finalSample.value = sampleData;
            taskRef.value.processedSample = [];
            clearTaskStructure();
        };

        const setParams = (params: any) => {
            taskRef.value.configuration.params = params;
        };

        const setFinalSample = (updatedFinalSample: any) => {
            finalSample.value = updatedFinalSample;
        };

        const saveTask = async () => {
            try {
                if (finalSample.value) {
                    const arraySample = R.is(Array, finalSample.value) ? finalSample.value : [finalSample.value];
                    taskRef.value.configuration.sample = arraySample;
                    if (taskRef.value.configuration.params) {
                        taskRef.value.configuration.params.fields = arraySample.length
                            ? Object.keys(arraySample[0])
                            : [];
                    }
                }
                await save();
            } catch (e) {
                (root as any).$toastr.e('Failed to save task', 'An error occurred!');
                throw e;
            }
        };

        const finalizeTask = async () => {
            if (!canBeFinalized.value) return;
            try {
                loadingFileHarvester.value = true;
                await saveTask();
                const folder = 'upload';
                const filesToUpload: any[] = [];
                if (taskRef.value.configuration.fileType !== FileType.Parquet) {
                    filesToUpload.push({
                        file: files.value.sampleFile as TFile,
                        sample: true,
                        policy: {
                            folder,
                            subfolder: `sample/${new Date().valueOf().toString()}`,
                        },
                    });
                }
                if (!taskRef.value.pipeline.isOnPremise) {
                    filesToUpload.push({
                        file: files.value.dataFile as TFile,
                        policy: {
                            folder,
                            subfolder: new Date().valueOf().toString(),
                        },
                    });
                }
                await uploadPolicyFiles(filesToUpload);
                await finalize();
                showFinalizeModal.value = true;
            } catch {
                (root as any).$toastr.e('Failed to finalize task', 'An error occurred!');
            } finally {
                loadingFileHarvester.value = false;
            }
        };

        const appendFiles = () => {
            showAppendFileModal.value = true;
        };

        const appendAdditionalFile = async () => {
            loadingFileHarvester.value = true;
            await uploadPolicyFiles([
                {
                    file: files.value.dataFile as TFile,
                    policy: {
                        folder: 'upload',
                        subfolder: new Date().valueOf().toString(),
                    },
                },
            ]);
            await exec(ApolloAPI.run(props.task?.pipeline.id as string));
            loadingFileHarvester.value = false;
            root.$router.push({ name: 'data-checkin-jobs', query: store.state.queryParams.jobs });
        };

        watch(
            () => props.task,
            (newTask: ApolloTask<FileHarvesterConfiguration>) => {
                taskRef.value = newTask;
            },
        );

        onMounted(() => {
            subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
        });

        onBeforeUnmount(() => {
            unsubscribe(WebSocketsEvents.Workflow);
            leaveSocketRoom(WebSocketsRoomTypes.Workflow, props.task.pipeline?.id);
        });

        return {
            taskRef,
            files,
            sample,
            finalSample,
            setFiles,
            setSample,
            setParams,
            setFinalSample,
            showFinalizeModal,
            finalLoading,
            canBeFinalized,
            runOnSample,
            fileProgress,
            saveTask,
            finalizeTask,
            hasSampleRun,
            canExecuteSampleRun,
            canAppendFiles,
            appendFiles,
            showAppendFileModal,
            appendAdditionalFile,
            currentStep,
            wizardActions,
            loadingSampleRun,
            loadingFileHarvester,
            sampleFields,
            taskStructure,
        };
    },
});
