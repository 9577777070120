

















































import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { NullValues, QuasiIdentifierAnonymisationField } from '../../types';
import { is, isNil } from 'ramda';
import AnonymisationSection from './AnonymisationSection.vue';
import { integerValidator, requiredIfValidator } from '@/app/validators';

extend('required_if', requiredIfValidator);
extend('integer', integerValidator);

export default defineComponent({
    name: 'HandleNullValuesFieldSection',
    components: {
        ValidationProvider,
        AnonymisationSection,
    },
    model: {
        prop: 'nullValues',
        event: 'changed',
    },
    props: {
        nullValues: { type: Object as PropType<NullValues>, required: true },
        field: {
            type: Object as PropType<QuasiIdentifierAnonymisationField>,
            required: true,
        },
        isMultiple: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const selectedNullValues: Ref<NullValues> = computed({
            get: () => props.nullValues,
            set: (updated: NullValues) => {
                emit('changed', updated);
            },
        });

        const changeHandleNullValuesMethod = (method: string) => {
            if (method === 'keep') {
                selectedNullValues.value = {
                    keep: true,
                };
            } else {
                if (props.field.type === 'string') {
                    selectedNullValues.value = {
                        keep: false,
                        replaceWith: '',
                    };
                } else if (props.field.type === 'time') {
                    selectedNullValues.value = {
                        keep: false,
                        replaceWith: { hours: 0, minutes: 0, seconds: 0 },
                    };
                } else {
                    selectedNullValues.value = {
                        keep: false,
                        replaceWith: null,
                    };
                }
            }
        };

        const getReplaceWithValidationRules = () => ({
            required_if: !selectedNullValues.value.keep,
            ...(props.field.type === 'integer' && { integer: true }),
            ...(props.field.type === 'double' && { double: 7 }),
        });

        return { selectedNullValues, is, isNil, changeHandleNullValuesMethod, getReplaceWithValidationRules };
    },
});
