






























































































































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { FileWithIcon, FormBlock, TwButton, TwProgressBar } from '@/app/components';
import { useApolloTask, useHarvester } from '../../../composable';
import { FileType, WorkflowStatus } from '../../../constants';
import { ApolloTask, FileHarvesterConfiguration, FilesData } from '../../../types';
import { SampleUpload } from '../common';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as R from 'ramda';
import { parseString, processors } from 'xml2js';
import { useFilters } from '@/app/composable';
import { DocumentIcon, ChevronRightIcon } from '@vue-hero-icons/outline';

extend('filepath', {
    message: (field, args) => {
        const [filetype] = args ? args[0].split(',') : '';
        return `The specified ${field} is not a valid path\n Sample path for linux: /home/my_data/my_file${filetype}\n Sample path for Windows: C:\\Documents\\my_data\\my_file${filetype}`;
    },
    validate: (value, args) => {
        let filetype: string = args[0];
        filetype = filetype.split(',').join('|');
        const winPath = new RegExp(`([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?(${filetype})$`);

        const linuxPath = new RegExp(`^(/[^/]*)+/?(${filetype})$`);

        return winPath.test(value) || linuxPath.test(value);
    },
});

export default defineComponent({
    name: 'SetupFileHarvester',
    components: {
        FormBlock,
        ValidationObserver,
        ValidationProvider,
        SampleUpload,
        DocumentIcon,
        FileWithIcon,
        TwButton,
        ChevronRightIcon,
        TwProgressBar,
    },
    props: {
        task: {
            type: Object as PropType<ApolloTask<FileHarvesterConfiguration>>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        files: {
            type: Object as PropType<FilesData>,
            required: true,
        },
        sample: {
            type: [Array, Object],
            required: false,
        },
    },
    setup(props, { emit, root }) {
        const { formatBytes } = useFilters();
        const filesValidationRef = ref<any>(null);
        const validating = ref<boolean>(false);
        const { inDraftStatus, inUpdateStatus } = useApolloTask(computed(() => props.task));
        const necessaryFields = ref<any>(null);
        const extraInvalidFields = ref<any>(null);

        const fileTypeRef = computed(() => props.task.configuration.fileType);
        const sampleFile = computed(() => props.files.sampleFile);
        const uploadFile = computed(() => props.files.dataFile);
        const allowNext = computed(
            () =>
                props.task.processedSample?.length ||
                (!!props.files.sampleFile &&
                    ((!props.task.pipeline.isOnPremise && !!props.files.dataFile) || props.task.pipeline.isOnPremise)),
        );

        const {
            checkInvalidXML,
            reduceSampleValues,
            invalidFormat,
            rangeError,
            emptyFile,
            valPercentage,
            noCSVData,
            parseCSV,
            acceptedFiles,
            checkInvalidJSON,
            checkInvalidCSV,
            checkInvalidParquet,
            showValidationBar,
        } = useHarvester(root, emit, fileTypeRef, validating);

        const fileBlockInfo = computed(() => {
            if (props.task.canAddFiles && !props.task.pipeline.isOnPremise) {
                return {
                    title: 'Upload Additional File(s)',
                    description: 'Upload additional file(s) to be processed (if in csv, json, xml format).',
                };
            }
            if (props.task.pipeline.isOnPremise)
                return {
                    title: 'File(s) path',
                    description: 'Provide your file(s) paths to be processed (if in csv, json, xml, parquet format)',
                };
            return {
                title: 'Upload File(s)',
                description: 'Upload your file(s) to be processed (if in csv, json, xml, parquet format)',
            };
        });

        const errorAlert: any = ref({
            title: null,
            body: {
                necessary: null,
                invalid: null,
            },
        });

        const setErrorAlert = (value: string | null) => {
            errorAlert.value.title = value;
        };

        const clearErrorAlert = () => {
            errorAlert.value = {
                title: null,
                body: {
                    necessary: null,
                    invalid: null,
                },
            };
        };

        const validate = async () => {
            return filesValidationRef.value.validate();
        };

        const clearActualFile = (resetConfiguration = false) => {
            emit('files-changed', { dataFile: null });
            if (resetConfiguration) {
                props.task.configuration.response = {
                    basePath: 'res',
                    multiple: false,
                    selectedItems: [],
                };
            }
        };

        const getKeys = (obj: any, path = ''): any => {
            if (!obj || typeof obj !== 'object') return path;
            return Object.keys(obj)
                .map((key) => getKeys(obj[key], path ? [path, key].join('.') : key))
                .concat(Number.isNaN(Number(path)) ? [path] : []);
        };

        const getAllPaths = (obj: any) => {
            const allKeys = getKeys(obj).toString().split(',');
            const keys = allKeys.map((key: string) =>
                key
                    .split('.')
                    .filter((keyPart: string) => Number.isNaN(Number(keyPart)))
                    .join('.'),
            );
            return [...new Set(keys)];
        };

        const filetypeValidation = async (filetype: string, file: any) => {
            if (emptyFile.value) {
                clearActualFile();
                (root as any).$toastr.e(`Empty ${filetype} file!`, 'Error');
            } else if (invalidFormat.value) {
                clearActualFile();
                if (!errorAlert.value.title) {
                    (root as any).$toastr.e(`Invalid ${filetype} format!`, 'Error');
                }
            } else if (noCSVData.value) {
                (root as any).$toastr.e('The CSV file contains no data!', 'No Data!');
                clearActualFile();
            } else if (rangeError.value) {
                (root as any).$toastr.e('File is too large or contains multiple nested data', 'Error');
                clearActualFile();
            } else {
                emit('files-changed', { dataFile: file });
            }
        };

        /**
         * Compares the csv headers (columns) of the sample file with the csv headers of the full file to be uploaded.
         * If a header/ column in either csv file (sample or full file) is empty, then in the error displayed to the user,
         * it is written as 'empty column header'
         * @param data CSV text data to be parsed
         */
        const calculateInconsistenciesCSV = async (file: any) => {
            const results = await parseCSV(file, false, 10);
            const headers = results.meta.fields;
            if (!R.isNil(props.sample) && !R.isEmpty(props.sample)) {
                const sampleHeaders = props.task.configuration.params.fields;
                necessaryFields.value = sampleHeaders?.filter((h: any) => headers.indexOf(h) === -1) ?? [];
                necessaryFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = necessaryFields.value.indexOf(w);
                        necessaryFields.value[idx] = 'empty column header';
                    }
                });

                extraInvalidFields.value = headers.filter((h: any) => sampleHeaders?.indexOf(h) === -1);
                extraInvalidFields.value.forEach((w: any) => {
                    if (!w) {
                        const idx = extraInvalidFields.value.indexOf(w);
                        extraInvalidFields.value[idx] = 'empty column header';
                    }
                });

                // duplicate headers of file are considered as extra invalid fields
                const duplicateHeadersFile = headers.filter(
                    (header: any, idx: number) => headers.indexOf(header) !== idx,
                );
                if (duplicateHeadersFile.length) {
                    extraInvalidFields.value = extraInvalidFields.value.concat(duplicateHeadersFile);
                }
            }
        };

        const calculateFilteredPaths = (paths: any) => {
            const filteredPaths: any = [];
            paths.forEach((p: any) => {
                let pathWithoutSeparator = p.split('||');
                pathWithoutSeparator = pathWithoutSeparator.filter((v: any) => v !== '');
                if (!filteredPaths.find((fp: any) => fp.toString() === pathWithoutSeparator.toString())) {
                    filteredPaths.push(pathWithoutSeparator);
                }
            });
            return filteredPaths;
        };

        /**
         * Compares the structure of the uploaded sample with the full file to be uploaded
         * @param file Full file to be uploaded
         * @param fileType Format of full file to be uploaded (json, xml, csv)
         */
        const compareSampleAndFile = async (file: any, fileType: FileType) => {
            rangeError.value = false;
            let data = null;

            // extract/ parse the text data from the file
            if (fileType === FileType.XML) {
                const addAt = (attrName: string) => {
                    return `@${attrName}`;
                };
                const xmlData = await file.text();
                parseString(
                    xmlData,
                    {
                        attrkey: '@',
                        charkey: '$',
                        explicitCharkey: false,
                        trim: true,
                        emptyTag: {},
                        explicitArray: false,
                        mergeAttrs: true,
                        attrNameProcessors: [addAt],
                        attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                        valueProcessors: [processors.parseNumbers, processors.parseBooleans],
                    },
                    (err, result) => {
                        if (!err) {
                            if (R.type(result) === 'Object') {
                                data = [result];
                            } else {
                                data = result;
                            }
                        }
                    },
                );
            } else {
                data = await file.text();
            }

            if (fileType === FileType.CSV) {
                await calculateInconsistenciesCSV(file);
            }

            if ([FileType.JSON, FileType.XML].includes(fileType)) {
                let fileUpload: any = fileType === 'json' ? JSON.parse(data) : data;
                if (props.task.configuration.isSampleCropped) {
                    fileUpload = await reduceSampleValues(fileUpload);
                }

                // remove any paths which are identical
                let invalidPaths = [];

                const paths = getAllPaths(props.sample);
                try {
                    invalidPaths = getAllPaths(fileUpload);
                } catch {
                    rangeError.value = true;
                    return;
                }

                necessaryFields.value = [];
                extraInvalidFields.value = [];

                const filteredPaths = await calculateFilteredPaths(paths);
                const filteredInvalidPaths = await calculateFilteredPaths(invalidPaths);

                // calculate which fields exist in the sample, but not in the full file to be uploaded
                filteredPaths.forEach((fp: any) => {
                    if (!filteredInvalidPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        necessaryFields.value.push(fp);
                    }
                });

                // calculate which fields exist in the full file to be uploaded, but not in the sample
                filteredInvalidPaths.forEach((fp: any) => {
                    if (!filteredPaths.find((ip: any) => ip.toString() === fp.toString())) {
                        extraInvalidFields.value.push(fp);
                    }
                });

                // remove any paths which are identical
                necessaryFields.value = [...new Set(necessaryFields.value)];
                extraInvalidFields.value = [...new Set(extraInvalidFields.value)];
            }

            if (necessaryFields.value && necessaryFields.value.length) {
                invalidFormat.value = true;
                errorAlert.value.title = `Inconsistencies detected between the Sample and the File "${file.name}"`;
                (root as any).$toastr.e('File and Sample do not have the same structure!', 'Error');
            } else if (props.task.configuration.isSampleCropped) {
                invalidFormat.value = false;
                errorAlert.value.title = `Inconsistencies may appear between the Sample and the File "${file.name}", because the Sample has been cropped.`;
            } else {
                invalidFormat.value = false;
                errorAlert.value.title = null;
                errorAlert.value.body = {
                    necessary: null,
                    invalid: null,
                };
            }
        };

        const fileUploaded = async (event: any) => {
            const { files } = event.target;
            const uploadedFile = files[0];
            const filename = uploadedFile.name;
            clearErrorAlert();

            if (!props.task.files.includes(filename)) {
                emit('set-loading', true);

                switch (props.task.configuration.fileType) {
                    case FileType.JSON: {
                        await checkInvalidJSON(uploadedFile);

                        if (!invalidFormat.value && !emptyFile.value) {
                            await compareSampleAndFile(R.clone(uploadedFile), FileType.JSON);
                        }
                        await filetypeValidation('JSON', uploadedFile);
                        break;
                    }
                    case FileType.XML: {
                        await checkInvalidXML(R.clone(uploadedFile));
                        if (!invalidFormat.value && !emptyFile.value) {
                            await compareSampleAndFile(uploadedFile, FileType.XML);
                        }
                        await filetypeValidation('XML', uploadedFile);
                        break;
                    }
                    case FileType.CSV: {
                        validating.value = true;
                        emit('files-changed', { dataFile: null });
                        await checkInvalidCSV(uploadedFile);
                        if (!invalidFormat.value && !emptyFile.value) {
                            await compareSampleAndFile(uploadedFile, FileType.CSV);
                        }
                        await filetypeValidation('CSV', uploadedFile);
                        break;
                    }
                    case FileType.Parquet: {
                        await checkInvalidParquet(uploadedFile);
                        await filetypeValidation('parquet', uploadedFile);
                        break;
                    }
                    default:
                        emit('files-changed', { dataFile: uploadedFile });
                }
            } else {
                (root as any).$toastr.e(`File with filname "${filename} already uploaded!`, 'Error');
                emit('files-changed', { dataFile: null });
            }
            await validate();
            emit('set-loading', false);
        };

        const nextTab = () => {
            if (props.task.configuration.fileType === FileType.Parquet && !props.task.processedSample?.length) {
                emit('run-on-sample');
            } else emit('next-tab');
        };

        return {
            formatBytes,
            WorkflowStatus,
            acceptedFiles,
            FileType,
            filesValidationRef,
            inDraftStatus,
            inUpdateStatus,
            errorAlert,
            setErrorAlert,
            clearErrorAlert,
            validate,
            clearActualFile,
            fileBlockInfo,
            fileUploaded,
            sampleFile,
            uploadFile,
            valPercentage,
            showValidationBar,
            validating,
            nextTab,
            allowNext,
        };
    },
});
