
















































































































































































import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { AnonymisationField, NumericalFieldLeveling, NumericalGroupAnonymisationField } from '../../types';
import HandleNullValuesFieldSection from './HandleNullValuesFieldSection.vue';
import PreviewAnonymisationRules from './PreviewAnonymisationRules.vue';
import { MappingFieldConfiguration } from '../../types/typings';
import { useQuasiIdentifierAnonymisation } from '../../composable';
import { ValidationProvider, extend } from 'vee-validate';
import { InputErrorIcon } from '@/app/components';
import { clone, is, isEmpty, isNil } from 'ramda';
import {
    doubleValidator,
    integerValidator,
    maxValueValidator,
    minValueValidator,
    requiredValidator,
} from '@/app/validators';
import AnonymisationNilValue from './AnonymisationNilValue.vue';
import { TrashIcon, PlusCircleIcon } from '@vue-hero-icons/outline';
import { useFilters } from '@/app/composable';
import NumericalGroupRule from './rules/NumericalGroupRule.vue';

extend('required', requiredValidator);
extend('min_value', minValueValidator);
extend('max_value', maxValueValidator);
extend('integer', integerValidator);
extend('double', doubleValidator);

export default defineComponent({
    name: 'NumericalGroupAnonymisationField',
    model: {
        prop: 'field',
        event: 'changed',
    },
    props: {
        field: {
            type: Object as PropType<NumericalGroupAnonymisationField>,
            required: true,
        },
        inEdit: { type: Boolean, default: false },
        mappingField: {
            type: Object as PropType<MappingFieldConfiguration & { originalTitle: string }>,
            required: true,
        },
        sample: { type: Array, required: true },
    },
    components: {
        HandleNullValuesFieldSection,
        PreviewAnonymisationRules,
        ValidationProvider,
        InputErrorIcon,
        AnonymisationNilValue,
        TrashIcon,
        PlusCircleIcon,
        NumericalGroupRule,
    },
    setup(props, { emit }) {
        const selectedField: Ref<NumericalGroupAnonymisationField> = computed({
            get: () => props.field,
            set: (updatedField: AnonymisationField) => {
                emit('changed', updatedField);
            },
        });
        const levelInputs = ref<{ from: string | null; to: string | null; label?: string | null }[][]>([]);
        const mappingFieldRef = computed(() => props.mappingField);
        const sampleRef = computed(() => props.sample);
        const { getSampleValue, baseRule, rules } = useQuasiIdentifierAnonymisation(
            selectedField,
            mappingFieldRef,
            sampleRef,
        );
        const { countDecimals } = useFilters();

        const hasLabels = computed(() => {
            for (let i = 0; i < selectedField.value.options.levels.length; i += 1) {
                for (let j = 0; j < selectedField.value.options.levels[i].length; j += 1) {
                    const level = selectedField.value.options.levels[i][j];
                    if (!isNil(level.label) && level.label.length > 0) return true;
                }
            }
            return false;
        });

        const addNumericalGroup = (level: number) => {
            selectedField.value.options.levels[level].push({ from: null, to: null });
        };

        const addGeneralisationLevel = () => {
            selectedField.value.options.levels.push([{ from: null, to: null }]);
        };

        const removeNumericalGroup = (level: number, group: number) => {
            if (group === 0 && selectedField.value.options.levels.length === 1) {
                selectedField.value.options.levels = [[{ from: null, to: null }]];
            } else if (group === 0 && selectedField.value.options.levels[level].length === 1) {
                selectedField.value.options.levels.splice(level, 1);
            } else {
                selectedField.value.options.levels[level].splice(group, 1);
            }
        };

        const getLabelPlaceholder = (group: { from: string | null; to: string | null }) => {
            if (!hasLabels.value) {
                if (group.from && group.from.length > 0 && group.to && group.to.length > 0) {
                    return `${group.from}-${group.to}`;
                }
                return 'optional';
            }
            return null;
        };

        const setLevels = () => {
            selectedField.value.options.levels = levelInputs.value.map((level) =>
                level.map((group) => {
                    return {
                        from: isNil(group.from) || isEmpty(group.from.trim()) ? null : Number(group.from),
                        to: isNil(group.to) || isEmpty(group.to.trim()) ? null : Number(group.to),
                        label: isNil(group.label) || isEmpty(group.label.trim()) ? null : group.label.trim(),
                    };
                }),
            );
        };

        const validationMessage = computed((): { title: string; message: string } | undefined => {
            let increment = 1;
            let value = getSampleValue(props.sample[0]) as number | null;
            if (!isNil(value)) increment = 1 / 10 ** countDecimals(value);
            for (let i = 0; i < selectedField.value.options.levels.length; i += 1) {
                for (let j = 0; j < selectedField.value.options.levels[i].length; j += 1) {
                    if (
                        Number(selectedField.value.options.levels[i][j].to) <=
                        Number(selectedField.value.options.levels[i][j].from)
                    ) {
                        return {
                            title: 'Incorrect Values',
                            message: `Please make sure that all numerical groups have valid values ('to' values must be greater than 'from' values).`,
                        };
                    }
                    if (
                        j > 0 &&
                        Number(selectedField.value.options.levels[i][j].from) <=
                            Number(selectedField.value.options.levels[i][j - 1].to)
                    ) {
                        return {
                            title: 'Overlapping Value',
                            message: `Please make sure that there are no overlapping values between the numerical groups.`,
                        };
                    }

                    if (
                        j > 0 &&
                        Number(selectedField.value.options.levels[i][j].from) - increment >
                            Number(selectedField.value.options.levels[i][j - 1].to)
                    ) {
                        return {
                            title: 'Discontinuous Numerical Groups',
                            message: `Please make sure that there are no values outside the specified range.`,
                        };
                    }
                }
            }
            return undefined;
        });

        const getExampleValue = (
            row: Record<string, any>,
            index: number,
        ): { from: number | null; to?: number } | string | null => {
            const value = getSampleValue(row) as number | null;

            if (isNil(value)) return { from: null };
            if (index === 0) return { from: Number(value) };

            const level = rules.value[index - 1];
            for (let g = 0; level && g < level.groupRules.length; g++) {
                const group = level.groupRules[g];

                if (
                    !isNil(group) &&
                    !isNil(group.fromValue) &&
                    !isNil(group.toValue) &&
                    value >= group.fromValue &&
                    value <= group.toValue
                ) {
                    return group.labelValue;
                }
            }

            return '*';
        };

        const getLevelingValidationRules = () => ({
            required: true,
            ...(props.field.type === 'integer' && { integer: true }),
            ...(props.field.type === 'double' && { double: 7 }),
        });

        watch(
            () => props.field,
            (updatedField) => {
                levelInputs.value = clone(updatedField.options.levels).map((level) =>
                    level.map((group) => {
                        return {
                            from: isNil(group.from) ? null : `${group.from}`,
                            to: isNil(group.to) ? null : `${group.to}`,
                            label: isNil(group.label) ? null : `${group.label}`,
                        };
                    }),
                );
            },
            { immediate: true, deep: true },
        );

        watch(
            () => props.field.options.leveling,
            (leveling: NumericalFieldLeveling) => {
                if (leveling === 'auto' && props.field.options.levels.length > 1)
                    selectedField.value.options.levels = [props.field.options.levels[0]];
            },
            { immediate: true },
        );

        return {
            is,
            isNil,
            selectedField,
            rules,
            baseRule,
            hasLabels,
            validationMessage,
            levelInputs,
            setLevels,
            addGeneralisationLevel,
            addNumericalGroup,
            removeNumericalGroup,
            getExampleValue,
            getLabelPlaceholder,
            getLevelingValidationRules,
        };
    },
});
