

















































































































































































































































































































































































import { ConfirmButton } from '@/app/components';
import { S } from '@/app/utilities';
import { ExclamationIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { useCleaning } from '../../../composable';
import {
    ConstraintOptionsType,
    HarvesterBlockId,
    conditionsOptions,
    constraintOptions,
    notSupportedMultipleConstraints,
} from '../../../constants';
import { CleaningFieldConfiguration, Constraint, ConstraintType } from '../../../types/cleaning.type';
import OutliersRule from '../OutliersRule.vue';
import ConstraintErrors from './ConstraintErrors.vue';

export default defineComponent({
    name: 'EditConstraint',
    props: {
        availableConstraintOptions: {
            type: Array as PropType<ConstraintOptionsType[]>,
            required: true,
        },
        alreadyDefinedConstraintTypes: {
            type: Array,
            default: () => [],
        },
        constraint: {
            type: Object as PropType<Constraint>,
            required: true,
        },
        selectedFieldsTypes: {
            type: Array as PropType<string[]>,
            required: true,
        },
        referencedFields: {
            type: Array as PropType<CleaningFieldConfiguration[]>,
            required: true,
        },
        harvesterBlockId: {
            type: String as PropType<HarvesterBlockId>,
            required: true,
        },
        previousProcessedSample: {
            type: Array,
            default: null,
        },
        isAdvancedConstraint: {
            type: Boolean,
            default: false,
        },
        isPlain: {
            type: Boolean,
            default: false,
        },
        multipleField: {
            type: Boolean,
            default: false,
        },
        canRunManyTimes: {
            type: Boolean,
            default: false,
        },
        allowDropOrDefaultOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConfirmButton,
        OutliersRule,
        ExclamationIcon,
        ConstraintErrors,
    },
    setup(props: any, { emit }: { emit: any }) {
        const newConstraint = ref<Constraint>(props.constraint);
        const isNew = ref<boolean>(!newConstraint.value.type);

        // current precision is 7 decimal places
        const precision = Math.pow(10, 7);

        // the min and max values for the range contraints must have the same decimal places precision as the step check
        const minValidation = computed(() => {
            if (props.selectedFieldsTypes.includes('double') || props.selectedFieldsTypes.includes('float')) {
                return Math.round(newConstraint.value.details?.from * precision) / precision;
            } else {
                return Math.trunc(newConstraint.value.details?.from);
            }
        });

        const maxValidation = computed(() => {
            if (props.selectedFieldsTypes.includes('double') || props.selectedFieldsTypes.includes('float')) {
                return Math.round(newConstraint.value.details?.to * precision) / precision;
            } else {
                return Math.trunc(newConstraint.value.details?.to);
            }
        });

        const newConstraintOptions = computed(() => {
            // if field is multiple, filter out any unsupported constraints
            let availableConstraintOptions = props.availableConstraintOptions;

            if (
                [
                    HarvesterBlockId.Kafka,
                    HarvesterBlockId.ExternalKafka,
                    HarvesterBlockId.MQTT,
                    HarvesterBlockId.ExternalMQTT,
                ].includes(props.harvesterBlockId)
            ) {
                availableConstraintOptions = availableConstraintOptions.filter(
                    (option: any) =>
                        ![ConstraintType.UNIQUE, ConstraintType.FOREIGN_KEY, ConstraintType.NOT_FOREIGN_KEY].includes(
                            option.id,
                        ),
                );
            }

            const options = props.multipleField
                ? availableConstraintOptions.filter(
                      (option: any) => !notSupportedMultipleConstraints.includes(option.id),
                  )
                : availableConstraintOptions;

            if (isNew.value) {
                // remove already defined constraints
                return options.filter(
                    (option: any) => !props.alreadyDefinedConstraintTypes.find((con: any) => con === option.id),
                );
            }
            return options;
        });

        const newConditionsOptions = computed(() => {
            if (props.selectedFieldsTypes.includes('string') || props.selectedFieldsTypes.includes('boolean')) {
                // remove GREATER_THAN and SMALLER_THAN
                return conditionsOptions.slice(0, 2);
            }
            return conditionsOptions;
        });

        const canAddConstraint = computed(() => {
            if (!newConstraint.value?.outliersRule?.type && !props.isAdvancedConstraint) return false;
            switch (newConstraint.value.type) {
                case ConstraintType.RANGE:
                case ConstraintType.NOT_RANGE:
                case ConstraintType.RANGE_EXCLUDING:
                    if (
                        newConstraint.value.details?.from === null ||
                        newConstraint.value.details?.from === '' ||
                        newConstraint.value.details?.to === null ||
                        newConstraint.value.details?.to === ''
                    ) {
                        return false;
                    }
                    break;
                case ConstraintType.REGULAR_EXPRESSION:
                case ConstraintType.NOT_REGULAR_EXPRESSION:
                    if (
                        newConstraint.value.details?.regularExpression === null ||
                        newConstraint.value.details?.regularExpression === ''
                    ) {
                        return false;
                    }
                    break;
                case ConstraintType.MANDATORY:
                case ConstraintType.UNIQUE:
                    break;
                case ConstraintType.SET_MEMBERSHIP:
                    if (newConstraint.value.details?.codeList === null) {
                        return false;
                    }
                    break;
                case ConstraintType.CROSS_FIELD:
                    if (
                        newConstraint.value.details?.conditions.filter(
                            (condition: any, index: number) =>
                                condition.conditionalOperator === null ||
                                condition.field === null ||
                                (condition.logicalOperator === null && index !== 0),
                        ).length > 0
                    ) {
                        return false;
                    }
                    break;
                case ConstraintType.FOREIGN_KEY:
                case ConstraintType.NOT_FOREIGN_KEY:
                    if (newConstraint.value.details?.field === null) {
                        return false;
                    }
                    break;
                default:
                    return false;
            }
            if (props.isAdvancedConstraint && props.multipleField && newConstraint.value.multipleOperator === 'none') {
                return false;
            }
            if (!props.isAdvancedConstraint && newConstraint.value.outliersRule?.type) {
                if (
                    newConstraint.value.outliersRule.type === 'DEFAULT_VALUE' &&
                    (newConstraint.value.outliersRule.replaceValue === null ||
                        newConstraint.value.outliersRule.replaceValue === '')
                ) {
                    return false;
                }
                return !(
                    newConstraint.value.outliersRule.type === 'PREVIOUS_VALUE' &&
                    (newConstraint.value.outliersRule.secondaryRule?.replaceValue === null ||
                        newConstraint.value.outliersRule.secondaryRule?.replaceValue === '')
                );
            }
            return true;
        });

        const showBatchDataWarning = computed(
            () =>
                props.canRunManyTimes &&
                [ConstraintType.UNIQUE, ConstraintType.FOREIGN_KEY, ConstraintType.NOT_FOREIGN_KEY].includes(
                    newConstraint.value?.type!,
                ),
        );

        const setDetails = (type: ConstraintType) => {
            newConstraint.value.type = type;
            if (!props.isAdvancedConstraint) {
                newConstraint.value.outliersRule = {
                    type: null,
                    replaceValue: null,
                    secondaryRule: null,
                };
            }
            switch (type) {
                case ConstraintType.RANGE:
                case ConstraintType.NOT_RANGE:
                case ConstraintType.RANGE_EXCLUDING:
                    newConstraint.value.details = {
                        from: null,
                        to: null,
                    };
                    if (props.selectedFieldsTypes.includes('time')) {
                        newConstraint.value.details.from = new Date().setHours(0, 0, 0, 0);
                        newConstraint.value.details.to = new Date().setHours(0, 0, 0, 0);
                    }
                    break;
                case ConstraintType.REGULAR_EXPRESSION:
                case ConstraintType.NOT_REGULAR_EXPRESSION:
                    newConstraint.value.details = {
                        regularExpression: null,
                    };
                    break;
                case ConstraintType.SET_MEMBERSHIP:
                    newConstraint.value.details = {
                        codeList: null,
                    };
                    break;
                case ConstraintType.CROSS_FIELD:
                    newConstraint.value.details = {
                        conditions: [
                            {
                                conditionalOperator: null,
                                field: null,
                                logicalOperator: null,
                            },
                        ],
                    };
                    break;
                case ConstraintType.FOREIGN_KEY:
                case ConstraintType.NOT_FOREIGN_KEY:
                    newConstraint.value.details = {
                        field: null,
                    };
                    break;
                default:
                    newConstraint.value.details = {};
            }
        };

        const addCondition = () => {
            newConstraint.value.details?.conditions.push({
                conditionalOperator: null,
                field: null,
                logicalOperator: null,
                parentFieldName: newConstraint.value.fieldName,
            });
        };

        const deleteCondition = (index: number) => {
            newConstraint.value.details?.conditions.splice(index, 1);
        };

        const typesRef = computed(() => props.selectedFieldsTypes);
        const { getDatetimeText, setDateTime, parseTimeStringToDate } = useCleaning(typesRef);

        const rangeError = computed(() => {
            const errorsArray: string[] = [];
            if (
                [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                    newConstraint.value.type!,
                ) &&
                newConstraint.value.details?.from &&
                newConstraint.value.details?.to
            ) {
                let { from } = newConstraint.value.details;
                let fromText = newConstraint.value.details.from;
                let { to } = newConstraint.value.details;
                let toText = newConstraint.value.details.to;

                if (
                    props.selectedFieldsTypes.includes('datetime') ||
                    props.selectedFieldsTypes.includes('date') ||
                    props.selectedFieldsTypes.includes('time')
                ) {
                    from = new Date(from);
                    fromText = getDatetimeText(from);
                    to = new Date(to);
                    toText = getDatetimeText(to);
                } else if (
                    props.selectedFieldsTypes.includes('integer') ||
                    props.selectedFieldsTypes.includes('double') ||
                    props.selectedFieldsTypes.includes('float')
                ) {
                    from = Number(from);
                    to = Number(to);
                }

                if (from > to) {
                    errorsArray.push(`Invalid range <span class="font-bold">"${fromText} - ${toText}"</span>.`);
                }
            }
            return R.uniq(errorsArray);
        });

        const regexError = computed(() => {
            const errorsArray: string[] = [];
            if (
                [ConstraintType.REGULAR_EXPRESSION, ConstraintType.NOT_REGULAR_EXPRESSION].includes(
                    newConstraint.value.type!,
                ) &&
                newConstraint.value.details?.regularExpression
            ) {
                try {
                    RegExp(newConstraint.value.details.regularExpression);
                } catch {
                    errorsArray.push(
                        `Invalid regular expression <span class="font-bold">"${S.sanitizeHtml(
                            newConstraint.value.details.regularExpression,
                        )}"</span>.`,
                    );
                }
            }
            return R.uniq(errorsArray);
        });

        const crossFieldError = computed(() => {
            const errorsArray: string[] = [];
            if (
                newConstraint.value.type === ConstraintType.CROSS_FIELD &&
                newConstraint.value.details?.conditions &&
                newConstraint.value.details.conditions.length > 0
            ) {
                const conditionFields: any = {};
                newConstraint.value.details.conditions.forEach((cond: any, index: number) => {
                    const conditionField = `${cond.conditionalOperator}-${cond.field}`;

                    if (conditionField in conditionFields) {
                        errorsArray.push(
                            `Condition <span class="font-bold">"${
                                conditionsOptions.find((con: any) => con.id === cond.conditionalOperator)?.label
                            }"</span> is defined for the field <span class="font-bold">"${cond.field.replace(
                                /__/g,
                                ' > ',
                            )}"</span> more than once.`,
                        );
                    } else {
                        conditionFields[conditionField] = {
                            logicalOperator: cond.logicalOperator,
                            index,
                        };
                    }
                });
                props.referencedFields.forEach((field: any) => {
                    const error = `Conflicting conditions <span class="font-bold">"{}"</span> and <span class="font-bold">"{}"</span> are defined for the same field <span class="font-bold">"${field.name.replace(
                        /__/g,
                        ' > ',
                    )}"</span>.`;
                    if (`EQUAL-${field.name}` in conditionFields && `NOT_EQUALS-${field.name}` in conditionFields) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`NOT_EQUALS-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is not equal to');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is not equal to');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`SMALLER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is smaller than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is smaller than');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is equal to');
                            e = e.replace('{}', 'is greater than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is greater than');
                            e = e.replace('{}', 'is equal to');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        (conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'AND' ||
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'AND')
                    ) {
                        const idx =
                            conditionFields[`SMALLER_THAN-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx === -1) {
                            let e = error.replace('{}', 'is smaller than');
                            e = e.replace('{}', 'is greater than');
                            errorsArray.push(e);
                        } else if (idx === 1) {
                            let e = error.replace('{}', 'is greater than');
                            e = e.replace('{}', 'is smaller than');
                            errorsArray.push(e);
                        }
                    }
                    if (
                        `EQUAL-${field.name}` in conditionFields &&
                        `SMALLER_THAN-${field.name}` in conditionFields &&
                        `GREATER_THAN-${field.name}` in conditionFields &&
                        ((conditionFields[`EQUAL-${field.name}`].logicalOperator === null &&
                            conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'OR' &&
                            conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'OR') ||
                            (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === null &&
                                conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === 'OR') ||
                            (conditionFields[`EQUAL-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`SMALLER_THAN-${field.name}`].logicalOperator === 'OR' &&
                                conditionFields[`GREATER_THAN-${field.name}`].logicalOperator === null))
                    ) {
                        let e = 'Condition <span class="font-bold">"{}"</span> is always true.';
                        const idx1 =
                            conditionFields[`EQUAL-${field.name}`].index -
                            conditionFields[`SMALLER_THAN-${field.name}`].index;
                        const idx2 =
                            conditionFields[`SMALLER_THAN-${field.name}`].index -
                            conditionFields[`GREATER_THAN-${field.name}`].index;
                        if (idx1 === -1 && idx2 === -1) {
                            e = e.replace('{}', 'is equal to OR is smaller than OR is greater than');
                            errorsArray.push(e);
                        } else if (idx1 === -2 && idx2 === 1) {
                            e = e.replace('{}', 'is equal to OR is greater than OR is smaller than');
                            errorsArray.push(e);
                        } else if (idx1 === 1 && idx2 === -2) {
                            e = e.replace('{}', 'is smaller than OR is equal to OR is greater than');
                            errorsArray.push(e);
                        } else if (idx1 === 2 && idx2 === -1) {
                            e = e.replace('{}', 'is smaller than OR is greater than OR is equal to');
                            errorsArray.push(e);
                        } else if (idx1 === -1 && idx2 === 2) {
                            e = e.replace('{}', 'is greater than OR is equal to OR is is smaller than');
                            errorsArray.push(e);
                        } else if (idx1 === 1 && idx2 === 1) {
                            e = e.replace('{}', 'is greater than OR is smaller than OR is equal to');
                            errorsArray.push(e);
                        }
                    }
                });
            }
            return R.uniq(errorsArray);
        });

        const decimalPlacesError = computed(() => {
            const errorsArray: string[] = [];

            if (
                [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                    newConstraint.value.type!,
                ) &&
                (props.selectedFieldsTypes.includes('double') || props.selectedFieldsTypes.includes('float'))
            ) {
                const fieldsToCheck = [
                    newConstraint.value.details?.from,
                    newConstraint.value.details?.to,
                    newConstraint.value.outliersRule?.replaceValue,
                    newConstraint.value.outliersRule?.secondaryRule?.replaceValue,
                ];

                const hasNoMoreThan7DecimalPlaces = (value: string) => {
                    return Math.round(Number(value) * precision) / precision === Number(value);
                };

                fieldsToCheck.forEach((field) => {
                    if (field && !hasNoMoreThan7DecimalPlaces(field)) {
                        errorsArray.push(
                            `Invalid number <span class="font-bold">"${field}"</span>. Must have no more than 7 decimal places.`,
                        );
                    }
                });
            }
            return R.uniq(errorsArray);
        });

        const integerError = computed(() => {
            const errorsArray: string[] = [];

            if (
                [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                    newConstraint.value.type!,
                ) &&
                props.selectedFieldsTypes.includes('integer')
            ) {
                const fieldsToCheck = [
                    newConstraint.value.details?.from,
                    newConstraint.value.details?.to,
                    newConstraint.value.outliersRule?.replaceValue,
                    newConstraint.value.outliersRule?.secondaryRule?.replaceValue,
                ];

                fieldsToCheck.forEach((field) => {
                    if (field && !Number.isInteger(Number(field))) {
                        errorsArray.push(
                            `Invalid number <span class="font-bold">"${field}"</span>. Must be an integer.`,
                        );
                    }
                });
            }
            return R.uniq(errorsArray);
        });

        const errors = computed(() => {
            return [
                ...rangeError.value,
                ...regexError.value,
                ...crossFieldError.value,
                ...decimalPlacesError.value,
                ...integerError.value,
            ];
        });

        const saveConstraint = () => {
            if (
                props.selectedFieldsTypes.includes('datetime') ||
                props.selectedFieldsTypes.includes('date') ||
                props.selectedFieldsTypes.includes('time')
            ) {
                if (
                    [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                        newConstraint.value.type!,
                    )
                ) {
                    if (newConstraint.value.details?.from) {
                        newConstraint.value.details.from = setDateTime(newConstraint.value.details.from);
                    }
                    if (newConstraint.value.details?.to) {
                        newConstraint.value.details.to = setDateTime(newConstraint.value.details.to);
                    }
                }
                if (!props.isAdvancedConstraint) {
                    if (newConstraint.value.outliersRule?.replaceValue) {
                        newConstraint.value.outliersRule.replaceValue = setDateTime(
                            newConstraint.value.outliersRule.replaceValue as string,
                        );
                    } else if (newConstraint.value.outliersRule?.secondaryRule?.replaceValue) {
                        newConstraint.value.outliersRule.secondaryRule.replaceValue = setDateTime(
                            newConstraint.value.outliersRule.secondaryRule.replaceValue as string,
                        );
                    }
                }
            }
            if (
                [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                    newConstraint.value.type!,
                ) &&
                (props.selectedFieldsTypes.includes('integer') ||
                    props.selectedFieldsTypes.includes('double') ||
                    props.selectedFieldsTypes.includes('float'))
            ) {
                if (newConstraint.value.details?.from) {
                    newConstraint.value.details.from = Number(newConstraint.value.details.from);
                }
                if (newConstraint.value.details?.to) {
                    newConstraint.value.details.to = Number(newConstraint.value.details.to);
                }
                if (!props.isAdvancedConstraint) {
                    if (newConstraint.value.outliersRule?.replaceValue) {
                        newConstraint.value.outliersRule.replaceValue = Number(
                            newConstraint.value.outliersRule.replaceValue,
                        );
                    } else if (newConstraint.value.outliersRule?.secondaryRule?.replaceValue) {
                        newConstraint.value.outliersRule.secondaryRule.replaceValue = Number(
                            newConstraint.value.outliersRule.secondaryRule.replaceValue,
                        );
                    }
                }
            }
            if (isNew.value) {
                emit('save-create');
            } else {
                emit('save-update');
            }
        };

        if (isNew.value) {
            emit('change-edit-mode', 'creating');
        } else {
            emit('change-edit-mode', 'updating');
        }

        if (!isNew.value) {
            if (props.selectedFieldsTypes.includes('time')) {
                const { details, outliersRule } = newConstraint.value;
                if (
                    [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                        newConstraint.value.type!,
                    ) &&
                    details
                ) {
                    details.from = parseTimeStringToDate(details.from as string);
                    details.to = parseTimeStringToDate(details.to as string);
                }
                if (!props.isAdvancedConstraint) {
                    if (outliersRule?.replaceValue) {
                        outliersRule.replaceValue = parseTimeStringToDate(outliersRule.replaceValue as string);
                    } else if (outliersRule?.secondaryRule?.replaceValue) {
                        const { secondaryRule } = outliersRule;
                        secondaryRule.replaceValue = parseTimeStringToDate(secondaryRule.replaceValue as string);
                    }
                }
            }
            if (props.selectedFieldsTypes.includes('datetime')) {
                if (
                    [ConstraintType.RANGE, ConstraintType.NOT_RANGE, ConstraintType.RANGE_EXCLUDING].includes(
                        newConstraint.value.type!,
                    )
                ) {
                    if (newConstraint.value.details?.from) {
                        newConstraint.value.details.from = new Date(newConstraint.value.details.from);
                    }
                    if (newConstraint.value.details?.to) {
                        newConstraint.value.details.to = new Date(newConstraint.value.details.to);
                    }
                }
                if (!props.isAdvancedConstraint && newConstraint.value.outliersRule?.replaceValue) {
                    newConstraint.value.outliersRule.replaceValue = new Date(
                        newConstraint.value.outliersRule.replaceValue as string,
                    );
                }
            }
        }

        return {
            emit,
            setDetails,
            conditionsOptions,
            isNew,
            addCondition,
            canAddConstraint,
            deleteCondition,
            constraintOptions,
            newConstraintOptions,
            newConditionsOptions,
            errors,
            saveConstraint,
            newConstraint,
            ConstraintType,
            showBatchDataWarning,
            minValidation,
            maxValidation,
        };
    },
});
