var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1 overflow-hidden"},[_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination":_vm.pagination,"loading":_vm.loading,"sortBy":_vm.query.sortBy},on:{"change-page":function($event){return _vm.$emit('change-page', $event)},"sort":_vm.changeSort},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedValue))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("-")])]}},{key:"name",fn:function(ref){
var value = ref.value;
var formattedValue = ref.formattedValue;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedValue))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"name-tooltip",fn:function(ref){
var value = ref.value;
var formattedTooltip = ref.formattedTooltip;
return [(value)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(formattedTooltip))]):_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("Pipeline does not exist")])]}},{key:"failedExecutions",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.failedExecutions / row.totalExecutions}}),_c('div',[_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(row.failedExecutions))]),_vm._v(" out of "+_vm._s(row.totalExecutions)+" ")])],1)]}},{key:"failedExecutions-tooltip",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center py-2 space-x-2 text-sm"},[_c('WeatherDynamicIcon',{attrs:{"weather":row.failedExecutions / row.totalExecutions}}),_c('div',[_vm._v(" "+_vm._s(row.failedExecutions)+" "+_vm._s(row.failedExecutions === 1 ? 'execution' : 'executions')+" failed out of "+_vm._s(row.totalExecutions)+" ")])],1)]}},{key:"lastExecution",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-row items-center flex-1 space-x-2 whitespace-nowrap",class:{
                    'text-green-700 hover:text-green-800': value.status === 'completed',
                    'text-red-700 hover:text-red-800': value.status === 'failed',
                    'text-yellow-700 hover:text-yellow-800':
                        value.status !== 'completed' && value.status !== 'failed',
                }},[(value.status === 'completed')?_c('CheckCircleIcon',{staticClass:"w-6 h-6"}):(value.status === 'failed')?_c('XCircleIcon',{staticClass:"w-6 h-6"}):_c('DotsCircleHorizontalIcon',{staticClass:"w-6 h-6"}),_c('span',[_vm._v(_vm._s(_vm.dateFromNow(value.timestamp, true)))])],1):_vm._e()]}},{key:"lastExecution-tooltip",fn:function(ref){
                var value = ref.value;
return [(value)?_c('div',{staticClass:"flex flex-col space-y-2"},[_c('span',{staticClass:"flex flex-row space-x-1 font-bold capitalize",class:{
                        'text-green-500 ': value.status === 'completed',
                        'text-red-500 ': value.status === 'failed',
                        'text-yellow-500 ': value.status !== 'completed' && value.status !== 'failed',
                    }},[(value.status === 'completed')?_c('CheckCircleIcon',{staticClass:"w-6 h-6"}):(value.status === 'failed')?_c('XCircleIcon',{staticClass:"w-6 h-6"}):_c('DotsCircleHorizontalIcon',{staticClass:"w-6 h-6"}),_c('span',[_vm._v(_vm._s(value.status))])],1),_c('span',[_vm._v(_vm._s(_vm.formatDate(value.timestamp, true, 'DD/MM/YY HH:mm', 'UTC')))])]):_vm._e()]}},{key:"errors",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reason")]):_c('div',{staticClass:"flex flex-row items-center space-x-1"},[_c('ExecutionError',{attrs:{"error":value[0].errorCode,"occurrences":value[0].count,"totalOccurrences":row.totalFailures}}),_c('QuestionMarkCircleIcon',{staticClass:"w-4 h-4"})],1)]}},{key:"errors-tooltip",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(value.length === 0)?_c('span',{staticClass:"italic text-neutral-600"},[_vm._v("No reasons for failure")]):_c('div',{staticClass:"flex flex-col p-3 space-y-1"},[_c('div',{staticClass:"font-bold"},[_vm._v("Reasons for failure")]),_c('div',{staticClass:"flex flex-col space-y-2"},_vm._l((_vm.R.min(value.length, 10)),function(v,vIdx){return _c('ExecutionError',{key:value[vIdx].errorCode,attrs:{"error":value[vIdx].errorCode,"occurrences":value[vIdx].count,"totalOccurrences":row.totalFailures,"extended":true}})}),1),(value.length > 10)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[_vm._v("More reasons exist...")]):_vm._e()])]}},{key:"metrics",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [_c('div',{staticClass:"flex flex-row items-center space-x-2"},[(_vm.supportMetrics(row))?_c('div',[(value && value.length > 0)?[_vm._v(_vm._s((value[0].completeness * 100).toFixed(2))+" %")]:[_vm._v("0 %")]],2):_c('div',{staticClass:"px-1 py-0.5 text-neutral-600 bg-neutral-200 rounded"},[_vm._v("N/A")])])]}},{key:"metrics-tooltip",fn:function(ref){
                    var value = ref.value;
                    var row = ref.row;
return [(value && value.length > 0)?_c('div',{staticClass:"flex flex-col p-2 space-y-3 text-sm"},[_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3"},[_c('div',[_vm._v(_vm._s(value[0].recordsStored === 1 ? 'Record' : 'Records')+" successfully stored")]),_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-green-600 rounded"},[_vm._v(" "+_vm._s(value[0].recordsStored)+" ")])]),_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3"},[_c('div',[_vm._v("Percentage of records dropped")]),(_vm.supportMetrics(row))?_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-yellow-600 rounded"},[_vm._v(" "+_vm._s(((value[0].recordsDropped / value[0].initialRecords) * 100).toFixed(0))+"% ")]):_c('div',{staticClass:"text-xs px-1 py-0.5 text-neutral-600 bg-neutral-200 rounded"},[_vm._v("N/A")])]),_c('div',{staticClass:"flex flex-row items-center justify-between space-x-3"},[_c('div',[_vm._v("Percentage of the records transformed")]),(_vm.supportMetrics(row))?_c('div',{staticClass:"px-1 py-0.5 text-xs text-white bg-blue-600 rounded"},[_vm._v(" "+_vm._s(((value[0].recordsTransformed / value[0].initialRecords) * 100).toFixed(0))+"% ")]):_c('div',{staticClass:"text-xs px-1 py-0.5 text-neutral-600 bg-neutral-200 rounded"},[_vm._v("N/A")])])]):_c('div',{staticClass:"italic text-neutral-400"},[_vm._v("No successful executions")])]}},{key:"footerRow",fn:function(){return [(_vm.rejectedItems)?[_vm._v(" Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")]:_vm._e()]},proxy:true},{key:"noRowsMessage",fn:function(){return [(!_vm.rejectedItems)?_c('span',[_vm._v("No data to show")]):_c('span',[_vm._v("Due to access level limitations, "+_vm._s(_vm.rejectedItems)+" item"+_vm._s(_vm.rejectedItems === 1 ? ' is' : 's are')+" omitted")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }